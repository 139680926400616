<template>
  <div class="bg-slate-700 pt-5 pb-5">
    <div class="content-wrapper">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-20 ps-10 sm:ps-0">
        <div class="col-span-1">
          <img class="w-52" src="@/assets/images/logo.png" alt="" />
          <p class="text-white text-start">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
            perferendis corporis dignissimos a, delectus corrupti optio iusto
            odio obcaecati ipsum?
          </p>
        </div>

        <div class="col-span-1">
          <div class="flex flex-col justify-center items-start mt-14">
            <h2 class=" mb-12 text-[#DD9D3C] font-extrabold ms-2">COMPANY</h2>
            <router-link class="text-white mb-2 flex items-center gap-2" to=""
              ><svg
                class="w-3 h-3 text-[#DD9D3C] dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9 5 7 7-7 7"
                />
              </svg>
              Home</router-link
            >
            <router-link class="text-white mb-2 flex items-center gap-2" to=""
              ><svg
                class="w-3 h-3 text-[#DD9D3C] dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9 5 7 7-7 7"
                />
              </svg>
              Services</router-link
            >
            <router-link class="text-white mb-2 flex items-center gap-2" to=""
              ><svg
                class="w-3 h-3 text-[#DD9D3C] dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9 5 7 7-7 7"
                />
              </svg>
              Pricing</router-link
            >
            <router-link class="text-white mb-2 flex items-center gap-2" to=""
              ><svg
                class="w-3 h-3 text-[#DD9D3C] dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9 5 7 7-7 7"
                />
              </svg>
              Contact</router-link
            >
          </div>
        </div>

        <div class="col-span-1">
            <div class="flex flex-col justify-center items-start mt-14">
                <h2 class=" mb-12 text-[#DD9D3C] font-extrabold">OFFICE 1</h2>
                <div class="flex items-start gap-3 mb-7">
                    <svg class="w-6 h-6 text-[#DD9D3C] dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z"/>
                      </svg>
                      <div>
                        <p class="text-white text-start">09-00000000</p>
                        <p class="text-white text-start">09-00000000</p>
                      </div>
                </div>
                <div class="flex items-start gap-3">
                    <svg class="w-6 h-6 text-[#DD9D3C] dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clip-rule="evenodd"/>
                      </svg>
                      
                      <div>
                        <p class="text-white text-start">No.23, Thamardi 2 <sup>nd</sup> St. Thingangyun, Yangon</p>
                      </div>
                </div>
            </div>
        </div>

        <div class="col-span-1">
            <div class="flex flex-col justify-center items-start mt-14">
                <h2 class=" mb-12 text-[#DD9D3C] font-extrabold">OFFICE 2</h2>
                <div class="flex items-start gap-3 mb-7">
                    <svg class="w-6 h-6 text-[#DD9D3C] dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z"/>
                      </svg>
                      <div>
                        <p class="text-white text-start">09-00000000</p>
                        <p class="text-white text-start">09-00000000</p>
                      </div>
                </div>
                <div class="flex items-start gap-3">
                    <svg class="w-6 h-6 text-[#DD9D3C] dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clip-rule="evenodd"/>
                      </svg>
                      
                      <div>
                        <p class="text-white text-start">No.23, Thamardi 2 <sup>nd</sup> St. Thingangyun, Yangon</p>
                      </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="w-full h-[1px] bg-[#918677] mt-10"></div>

    <p class="text-white text-sm mt-3 text-center px-5">&copy; copy right served @ 2024 by Aung Shan Toe Company | Developed by Wai Linn Htet Aung</p>
  </div>
</template>

<script>
export default {};
</script>
