<template>
    <div>
        <img data-aos="flip-up" class="w-full object-cover h-80 brightness-50" src="@/assets/images/about/banner.jpg" alt="">
    </div>
</template>

<script>
    export default {
        
    }
</script>

